<template>
  <t-split-screen centered>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <h1>{{ $t('pages.login.heading', { serviceName: $config.public.service.name }) }}</h1>
      <p class="text--grey">
        {{ $t('pages.login.lead') }}
      </p>

      <t-form class="mt-5" @submit.native.prevent="handleSubmit(login)">
        <t-validation-wrapper vid="email" :name="$t('fields.email_address')" rules="required|email">
          <t-input
            v-model="form.email"
            native-type="text"
            :placeholder="`${$t('fields.email_address')}...`"
          >
            <t-icon slot="right" :icon="['far', 'envelope']" />
          </t-input>
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="password"
          :name="$t('fields.password.password')"
          rules="required"
        >
          <password-input
            v-model="form.password"
            :placeholder="`${$t('fields.password.password')}...`"
          />
        </t-validation-wrapper>

        <t-grid-row compact class="mb-3 mt-3">
          <t-grid-cell>
            <t-checkbox v-model="form.remember">
              {{ $t('pages.login.actions.remember_me') }}
            </t-checkbox>
          </t-grid-cell>
          <t-grid-cell class="text--right">
            <nuxt-link :to="{ name: 'forgot-password' }">
              {{ $t('pages.login.actions.forgot_password') }}
            </nuxt-link>
          </t-grid-cell>
        </t-grid-row>

        <t-form-element>
          <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
            {{ $t('global.actions.login') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>

    <dashboard-image slot="secondary" name="login" />
  </t-split-screen>
</template>

<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import DashboardImage from '~/components/DashboardImage.vue';
import PasswordInput from '~/components/PasswordInput.vue';

export default Vue.extend({
  components: {
    DashboardImage,
    TValidationWrapper,
    PasswordInput,
  },

  layout: 'standalone',
  middleware: 'guest',

  data: () => ({
    form: {
      email: null,
      password: null,
      remember: false,
    },

    loading: false,
  }),

  methods: {
    async login() {
      this.loading = true;

      try {
        await this.$auth.login(this.form);

        if (this.$route.query.to) {
          await this.$router.pushExpectRedirect(this.$route.query.to);
          return;
        }

        await this.$router.pushExpectRedirect({ name: this.$RouteEnum.DASHBOARD });
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
